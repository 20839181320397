import { Carousel } from "shared/components/atoms/Carousel";
import Link from "next/link";
import { memo } from "react";
import { Avatar } from "shared/components/atoms/Avatar";
import { floor } from "shared/utils/Math";
import { IsWaitingStatusKeys } from "shared/models/FortuneTeller";
import { FortuneTeller } from "shared/types";
import tw from "twin.macro";
import { Paths } from "utils/routes";
import { fortuneTellerHelper } from "features/fortune-tellers/utils/fortuneTellerHelper";
import StarIcon from "shared/assets/icons/star.svg";
import { css } from "styled-components";
import { OfficialBadge } from "shared/features/shared/components/OfficialBadge";

const StarIconCss = css({ transform: "scale(.8)" });

const AnchorText = tw.a`flex flex-col items-center w-[90px] overflow-hidden`;

const Name = tw.span`font-base text-[11px] tracking-[.32px] my-2 truncate w-full text-center`;

const WaitingStatus = tw.div`w-4 h-4 rounded-full absolute bottom-[-6px] -right-0.5 border-8 border-solid border-white box-content`;

const Score = tw.span`text-[11px] font-semibold text-theme ml-[3px]`;

interface ItemProps {
  fortuneTeller: FortuneTeller;
  waitingStatusKey: IsWaitingStatusKeys;
}

const Item = ({ fortuneTeller, waitingStatusKey = "any" }: ItemProps) => {
  const { isWaiting } = fortuneTellerHelper(fortuneTeller);

  const waitingStatusCss = isWaiting(waitingStatusKey)
    ? tw`bg-theme`
    : tw`bg-[#DFE7F2]`;

  return (
    <Link href={Paths.fortuneTellers.show(fortuneTeller.id)} passHref>
      <AnchorText>
        <div tw="relative">
          <Avatar
            src={fortuneTeller.imageUrl}
            fallback={fortuneTeller.thumbnailUrl}
            size={90}
          />
          <WaitingStatus css={waitingStatusCss} />
          {fortuneTeller.isOfficial && (
            <div tw="absolute top-1.5 left-1.5 w-6 h-6">
              <OfficialBadge size={24} />
            </div>
          )}
        </div>
        <Name>{fortuneTeller.name}</Name>
        <div tw="flex-center">
          <StarIcon tw="svg text-theme" css={StarIconCss} />
          <Score>{floor(fortuneTeller.averageScore, 1).toFixed(1)}</Score>
        </div>
      </AnchorText>
    </Link>
  );
};

interface Props {
  fortuneTellers: FortuneTeller[];
  waitingStatusKey?: IsWaitingStatusKeys;
  onClick?: (data: FortuneTeller, index?: number) => void;
  className?: string;
}

export const SwipableFortuneTellerList = memo(
  ({ fortuneTellers, waitingStatusKey = "any", ...rest }: Props) => {
    return (
      <Carousel
        data={fortuneTellers}
        renderItem={(fortuneTeller) => (
          <Item
            key={fortuneTeller.id}
            fortuneTeller={fortuneTeller}
            waitingStatusKey={waitingStatusKey}
          />
        )}
        {...rest}
      />
    );
  },
  (prevProps: Props, nextProps: Props) => {
    return (
      prevProps.fortuneTellers?.length === nextProps.fortuneTellers?.length &&
      prevProps.waitingStatusKey === nextProps.waitingStatusKey &&
      prevProps.onClick === nextProps.onClick &&
      prevProps.className === nextProps.className
    );
  }
);

SwipableFortuneTellerList.displayName = "SwipableFortuneTellerList";
